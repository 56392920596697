import { httpGetRequestV2, httpPutRequestV2 } from '@src/api/httpAPI/httpRequestAPI'
import { localStorageAPI } from '@src/api/storageAPI'

import { httpGetCalender } from '@src/api/httpAPI/httpCalender'
import SelectOption from '@src/components/SelectOption'
import TextField from '@src/components/TextField'
import useInfiniteScroll from '@src/hooks/useInfiniteScroll'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import LendOrderResponseTable from './LendOrderResponseTable'
import LogoutButton from '@src/components/Button/LogoutButton'
import { SpanCursorPointer } from '@src/components/Span/SpanCursorPointer'
import { downloadCsvWithDict } from '@src/util/getCsv'
import { csvDict, csvDictEn, csvHeader, csvHeaderEn, csvKey, csvKeyEn } from './csv'
import { Button } from '@mui/material'
import TradeButton from '@src/components/Button/TradeButton'
import { DateTime } from 'luxon'
import { REQUEST } from '@src/constants/NotiText'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { topNotiCountsState } from '@src/stores/topNotiCountsState'
import { httpGetTopNotiCounts } from '@src/api/httpAPI/httpNotiCountApi'
import { topBlinkState } from '@src/stores/topBlinkState'
import { confirmModalState } from '@src/stores/confirmModalState'
import { errorModalState } from '@src/stores/errorModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { getfailedUpdateText, getfailedUpdateTextEnglish } from '@src/util/getfailedUpdateText'
import { OverAmount } from '@src/components/Modal/OverAmount'
import RefreshIcon from '@mui/icons-material/Refresh'
import { forceRefreshState } from '@src/stores/forceRefreshState'
import { useTranslation } from 'react-i18next'

export interface QueryPayload {
  query?: string
  companyName?: string
  stockQuery?: string
  companyQuery?: string
  settlementType?: SettlementType | ''
  transactionType?: TransactionType | ''
  requestType?: string
  before?: string
  after?: string
}

const LendList: FunctionComponent = () => {
  const [stockQuery, setStockQuery] = useState<string>('')
  const [companyQuery, setCompanyQuery] = useState<string>('')
  const [isLast, setIsLast] = useState(true)
  const [page, setPage] = useState(0)
  const [isEmpty, setIsEmpty] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [today, setToday] = useState(DateTime.local().toFormat('yyyyMMdd'))
  const [queryPayload, setQueryPayload] = useState<QueryPayload>({
    stockQuery: '',
    companyQuery: '',
    settlementType: '',
    transactionType: '',
    requestType: 'ACCEPT,UPDATE',
    before: today,
    after: today,
  })

  const [selectedRequestId, setSelectedRequestId] = useState<number[]>([])
  const [requests, setRequests] = useState<RequestV2Type[]>([])
  const [selectedAcceptReq, setSelectedAcceptReq] = useState<RequestV2Type[]>([])
  const [valueChangedReq, setValueChangedReq] = useState<RequestV2Type[]>([])
  const [selectedUpdateReq, setSelectedUpdateReq] = useState<RequestV2Type[]>([])

  const [selectedIndex, setSelectedIndex] = useState<number[]>([])

  const [resetInputFlag, setResetInputFlag] = useState(false)

  const setTopNotiCounts = useSetRecoilState(topNotiCountsState)
  const [topBlink, setTopBlink] = useRecoilState(topBlinkState)

  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)
  const forceRefresh = useRecoilValue(forceRefreshState)
  const trans = useTranslation()

  // const fetchToday = async () => {
  //   const date = await httpGetCalender()
  //   setToday(date.today)
  // }

  // useEffect(() => {
  //   fetchToday()
  // }, [])

  const postShortCutEvent = (e) => {
    if (e.key === 'F8') {
      if (!isCanAccept()) {
        return
      }
      putRequests('ACCEPT')
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', postShortCutEvent)
    return () => {
      window.removeEventListener('keydown', postShortCutEvent)
    }
  }, [selectedRequestId, valueChangedReq])

  const refresh = () => {
    setQueryPayload({
      stockQuery: '',
      companyQuery: '',
      settlementType: '',
      transactionType: '',
      requestType: 'ACCEPT,UPDATE',
      before: today,
      after: today,
    })
    setPage(0)
    setStockQuery('')
    setCompanyQuery('')
    setSelectedRequestId([])
    setSelectedIndex([])
    setResetInputFlag(!resetInputFlag)
  }

  const putRequestsCallback = async (param: { responseType?: 'ACCEPT' | 'REJECT' | 'UPDATE' }) => {
    try {
      const responseList = requests
        .filter(({ requestId }) => selectedRequestId.includes(requestId))
        .map(
          ({
            orderId,
            newRequestedRate,
            currentRate,
            newRequestedVolume,
            requestId,
            partnerAccount,
            stock,
          }) => ({
            orderId,
            rate: newRequestedRate || currentRate,
            volume: newRequestedVolume,
            requestId,
            partnerAccount: partnerAccount,
            stock: stock,
          })
        )
      const responseListPayLoad = responseList.map(({ orderId, rate, volume, requestId }) => ({
        orderId,
        rate: rate,
        volume: volume,
        requestId,
      }))
      const res = await httpPutRequestV2({
        responseList: responseListPayLoad,
        responseType: param.responseType,
      })
      if (res['error'] === 'order volume is greater than remain volume') {
        let innerString = trans.t('rejectAndCheckPool')
        res.data.forEach((data) => {
          const _innerString = `${data.stock.issueName}(${data.stock.issueCode}) ${trans.t(
            'remainLendPool'
          )} ${data.remainVolume}${trans.t('volumeAndOver')} ${data.excessVolume}${trans.t(
            'singleVolume'
          )}\n`
          innerString += _innerString
        })
        setErrorModal({
          ...errorModal,
          isOpen: true,
          innerString: innerString + '\n\n',
        })
        return
      }
      const failedUpdateRequest = responseList.filter(
        (req) => !res.data.map((elem) => elem.orderId).includes(req.orderId)
      )
      setSelectedRequestId([])
      setSelectedIndex([])
      setRequests([])
      setQueryPayload({
        stockQuery: '',
        companyQuery: '',
        settlementType: '',
        transactionType: '',
        requestType: 'ACCEPT,UPDATE',
        before: today,
        after: today,
      })
      setPage(0)
      if (failedUpdateRequest.length === 0) {
        setToastModal({
          ...toastModal,
          isOpen: true,
          innerString: trans.t('modal.request.succeed'),
        })
        // alert(trans.t('modal.request.succeed'))
        const notiCounts = await httpGetTopNotiCounts()
        setTopNotiCounts(notiCounts)
        const topBlinkBorrow = { ...topBlink.borrow, requestCount: false }
        setTopBlink({ ...topBlink, borrow: topBlinkBorrow })
      } else {
        let alertText = getfailedUpdateText(failedUpdateRequest, localStorageAPI.getItem('lang'))
        setErrorModal({ ...errorModal, isOpen: true, innerString: alertText })
        // alert(alertText)
      }
    } catch (e) {
      setErrorModal({ ...errorModal, isOpen: true, innerString: trans.t('modal.request.fail') })
      console.error('err', e)
    }
  }

  // order volume exceed remain volume
  const putRequests = useCallback(
    async (responseType: RequestOptionType) => {
      if (responseType === 'ACCEPT' || responseType === 'UPDATE') {
        const selectedRequests = requests.filter((request) =>
          selectedRequestId.includes(request.requestId)
        )
        let isAmountOver: boolean
        let innerString = ''
        selectedRequests.forEach((request) => {
          if (request.requestType === 'ACCEPT') {
            if (responseType === 'ACCEPT' && request.currentVolume > request.poolVolume) {
              isAmountOver = true
              const string = trans.t('putRequestReject.case1')
              innerString = innerString.length === 0 ? string : innerString + '\n' + string
            }
            if (
              responseType === 'UPDATE' &&
              Number(request.newRequestedVolume) > request.poolVolume
            ) {
              isAmountOver = true
              const string = trans.t('putRequestReject.case2')
              innerString = innerString.length === 0 ? string : innerString + '\n' + string
            }
          }
          if (request.requestType === 'UPDATE') {
            if (request.requestedVolume > request.currentVolume) {
              isAmountOver = true
              const string = trans.t('putRequestReject.case3')
              innerString = innerString.length === 0 ? string : innerString + '\n' + string
            }
          }
        })
        if (isAmountOver === true) {
          setErrorModal({
            ...errorModal,
            isOpen: true,
            innerString: innerString,
            // responseType === 'UPDATE'
            //   ? '차입자 희망수량이 희망수량을 초과해 요청할 수 없습니다.'
            //   : '차입자 희망수량이 희망수량을 초과해 동의할 수 없습니다.',
          })
          return
        }
      }
      const innerString =
        responseType === 'REJECT'
          ? trans.t('confirms.reject')
          : responseType === 'ACCEPT'
          ? trans.t('confirms.accept')
          : trans.t('confirms.update')
      setConfirmModal({
        ...confirmModal,
        isOpen: true,
        innerString: innerString,
        confirmFunction: putRequestsCallback,
        closeFunction: () => {
          return
        },
        confirmFunctionParam: { responseType: responseType },
        // tab: tab,
      })
    },
    [requests, selectedRequestId, topBlink]
  )

  const getRequests = useCallback(async () => {
    try {
      const payload = Object.assign(
        {
          orderType: 'LEND' as OrderType,
          isRequester: false,
          requestStatus: 'RECEIVED',
          stockQuery: '',
          companyQuery: '',
          // page,
          settlementType: '',
          transactionType: '',
          requestType: 'ACCEPT,UPDATE',
          // size: 20,
        },
        queryPayload
      )
      const requests = await httpGetRequestV2(payload)

      // if (page == 0) {
      setRequests(requests)
      // }
      // else {
      //   setRequests((prevRequests) => [...prevRequests, ...requests])
      // }

      // setIsLast(requests.length < 1)
      setIsLast(true)
      setIsLoading(false)
      // if (page == 0 && !requests.length) {
      //   setIsEmpty(true)
      // } else {
      //   setIsEmpty(false)
      // }
    } catch {
      alert(trans.t('modal.request.serverError'))
    }
  }, [queryPayload, page])

  useEffect(() => {
    getRequests()
    // }, [])
  }, [queryPayload, page])

  useEffect(() => {
    isLoading === false && refresh()
  }, [forceRefresh])

  const handleObserver: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting) {
      setPage((page) => page + 1)
    }
  }

  const { onInfiniteScrollInit, onInfiniteScrollUpdate, onInfiniteScrollDisconnect } =
    useInfiniteScroll(handleObserver)

  // useEffect(() => {
  //   onInfiniteScrollInit(document.querySelector('.table-bottom'))
  // }, [])

  // useEffect(() => {
  //   if (isLoading || isLast) onInfiniteScrollDisconnect()
  //   else onInfiniteScrollUpdate()
  // }, [isLoading, isLast])

  const isKeyTabOrEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab') {
      return true
    }
    if (e.key === 'Enter') {
      return true
    }
    return false
  }

  const handleCsvDownload = useCallback(async () => {
    const payload = Object.assign(
      {
        orderType: 'LEND' as OrderType,
        isRequester: false,
        requestStatus: 'RECEIVED',
        stockQuery: '',
        companyQuery: '',
        settlementType: '',
        transactionType: '',
        requestType: 'ACCEPT,UPDATE',
        before: today,
        after: today,
      }
      // queryPayload
    )
    const requests = await httpGetRequestV2(payload)

    let csvInfo

    if (localStorageAPI.getItem('lang') === 'en') {
      csvInfo = {
        header: csvHeaderEn,
        key: csvKeyEn,
        dict: csvDictEn,
        fileName: 'Lending_BorrowRequest',
      }
    } else {
      csvInfo = {
        header: csvHeader,
        key: csvKey,
        dict: csvDict,
        fileName: '대여_거래응답',
      }
    }

    downloadCsvWithDict(requests, csvInfo.header, csvInfo.key, csvInfo.dict, csvInfo.fileName)
  }, [queryPayload])

  // useEffect(() => {
  //   const acceptReqs: RequestV2Type[] = []
  //   const rejectReqs: RequestV2Type[] = []
  //   requests.forEach((request) => {
  //     if (selectedRequestId.includes(request.requestId)) {
  //       if (request.requestType === 'ACCEPT') {
  //         acceptReqs.push(request)
  //       } else {
  //         rejectReqs.push(request)
  //       }
  //     }
  //   })
  //   setSelectedAcceptReq(acceptReqs)
  //   setSelectedUpdateReq(rejectReqs)
  // }, [selectedRequestId])

  const ClassifiedRequests = useMemo(() => {
    const acceptReqs: RequestV2Type[] = []
    const updateReqs: RequestV2Type[] = []
    requests.forEach((request) => {
      if (selectedRequestId.includes(request.requestId)) {
        if (request.requestType === 'ACCEPT') {
          acceptReqs.push(request)
        } else {
          updateReqs.push(request)
        }
      }
    })
    return {
      acceptReqs: acceptReqs,
      updateReqs: updateReqs,
    }
  }, [selectedRequestId, requests])

  // useEffect(() => {
  //   console.log('acc', selectedAcceptReq)
  //   console.log('rej', selectedRejectReq)
  // }, [selectedAcceptReq, setSelectedRejectReq])

  const isCanReject = () => {
    return selectedRequestId.length === 0 ? false : true
  }

  const isCanAccept = () => {
    if (selectedRequestId.length === 0) {
      return false
    }
    if (valueChangedReq.length === 0) {
      return true
    }
    console.log('selectedRequestId', selectedRequestId)
    console.log('requests', requests)
    const changedIds = valueChangedReq.map((req) => req.requestId)
    const isChangedIdsInSelectedRequestId = () => {
      let bool = false
      changedIds.forEach((id) => {
        if (selectedRequestId.includes(id)) {
          bool = true
        }
      })
      return bool
    }
    if (isChangedIdsInSelectedRequestId()) {
      return false
    }
    return true
  }

  const isCanUpdate = () => {
    if (selectedRequestId.length === 0) {
      return false
    }
    if (ClassifiedRequests.updateReqs.length > 0) {
      return false
    }
    return true
  }

  return (
    <div className="lend-order-response LEND">
      <OverAmount innerStringPrefix={'희망수량보다'}></OverAmount>
      <article>
        <div className="tab"></div>
        <div className="button-wrap">
          <TradeButton color="red" disabled={!isCanReject()} onClick={() => putRequests('REJECT')}>
            {trans.t('reject')}
          </TradeButton>
          <TradeButton
            color="yellow"
            disabled={!isCanUpdate()}
            onClick={() => putRequests('UPDATE')}
          >
            {trans.t('updateRequest')}
          </TradeButton>
          <TradeButton color="blue" disabled={!isCanAccept()} onClick={() => putRequests('ACCEPT')}>
            {trans.t('accept') + '(F8)'}
          </TradeButton>
        </div>
        <div className="option">
          <div className="first-line">
            <h3>{trans.t('searchInList')}</h3>
            <SpanCursorPointer onClick={refresh}>
              {/* 조건 초기화 */}
              <RefreshIcon></RefreshIcon>
              <p>&nbsp;{trans.t('refresh')}</p>
            </SpanCursorPointer>
          </div>
          <div className="second-line">
            <TextField
              label={trans.t('issue')}
              value={stockQuery}
              onChange={(e) => setStockQuery(e.target.value)}
              onKeyDown={(e: any) => {
                if (isKeyTabOrEnter(e)) {
                  setQueryPayload({ ...queryPayload, stockQuery: stockQuery })
                  setPage(0)
                }
              }}
            />
            <TextField
              label={trans.t('company')}
              value={companyQuery}
              onChange={(e) => setCompanyQuery(e.target.value)}
              onKeyDown={(e: any) => {
                if (isKeyTabOrEnter(e)) {
                  setQueryPayload({ ...queryPayload, companyQuery: companyQuery })
                  setPage(0)
                }
              }}
            />
          </div>
        </div>

        <LendOrderResponseTable
          setSelectedRequestIds={setSelectedRequestId}
          requests={requests}
          setRequests={setRequests}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          queryPayload={queryPayload}
          setQueryPayload={setQueryPayload}
          setPage={setPage}
          valueChangedReq={valueChangedReq}
          setValueChangedReq={setValueChangedReq}
          resetInputFlag={resetInputFlag}
          setResetInputFlag={setResetInputFlag}
        />

        <Button
          sx={{ marginLeft: '30px' }}
          variant="outlined"
          onClick={() => {
            handleCsvDownload()
          }}
        >
          {trans.t('downloadAllList')}
        </Button>
      </article>
    </div>
  )
}

export default LendList
