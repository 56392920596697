import { httpPostMessageToken } from '@src/api/httpAPI/httpUserAPI'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'

let isBlocked = true

const firebaseConfig = {
  apiKey: 'AIzaSyC__-RPzsC5qiuQ1cpGz9917QkH8EKc7xQ',
  authDomain: 'directional-611fe.firebaseapp.com',
  databaseURL: 'https://directional-611fe.firebaseio.com',
  projectId: 'directional-611fe',
  storageBucket: 'directional-611fe.appspot.com',
  messagingSenderId: '686541668969',
  appId: '1:686541668969:web:106556836c80bdc96598f8',
  measurementId: 'G-9DTY6Y7RNF',
}

const fbApp = initializeApp(firebaseConfig)

let messaging
if (typeof window !== 'undefined') {
  isSupported().then((supported) => {
    if (supported) {
      console.log('supported', supported)
      messaging = getMessaging(fbApp)
      // 메시징 관련 코드 추가
    } else {
      console.log('Firebase Messaging is not supported in this browser.')
    }
  })
}

const isNotificationSupported = 'Notification' in window
if (isNotificationSupported) {
  Notification.requestPermission().then((result) => {
    if (result === 'granted') {
      isBlocked = false
      console.log('notification granted')

      // Notification 권한이 허용되면 메시징 초기화
      const messaging = getMessaging(fbApp)
      getFcmToken() // getFcmToken 함수 호출
    } else {
      console.log('notification blocked')
      isBlocked = true
    }
  })
} else {
  console.log('Notification is not supported in this browser')
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope)
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err)
    })
}

export const getFcmToken = () => {
  getToken(messaging, {
    vapidKey:
      'BE1zNrGuestspK9Em-4UJWurWbCkZlsfPOawTZ3iPvY5zQctC4QrF0LoXQEstkFw8tMAsGJNkYkx4RX9K6aqRGg',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('fcm token sended')
        httpPostMessageToken(currentToken)
      } else {
        console.log('No registration token available. Request permission to generate one.')
      }
    })
    .catch((err) => {
      console.error('An error occurred while retrieving token. ', err)
    })
}

export default fbApp
