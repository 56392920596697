import styled from '@emotion/styled'
import { httpPostLogin, httpPostSubmit } from '@src/api/httpAPI/httpAuthAPI'
import { localStorageAPI, sessionStorageAPI } from '@src/api/storageAPI'
import Button from '@src/components/Button'
import Checkbox from '@src/components/Checkbox'
import Form from '@src/components/Form'
import TextField from '@src/components/TextField'
import { Dispatch, Fragment, useCallback, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router'

import { Box, Button as MuiButton, Modal } from '@mui/material'
import { LOGIN, MESSAGE, REQUEST, RESULT, VALUECHECK } from '@src/constants/NotiText'
import { useRecoilState } from 'recoil'
import { toastModalState } from '@src/stores/toastModalState'

import i18next from '@src/lang/i18n'
import { useTranslation } from 'react-i18next'
import useLang from '@src/hooks/useLang'
import User from '../User'
import { ToastModal } from '../../../components/Modal/ToastModal'

interface RegisterModalProps {
  isRegisterModal: boolean
  setIsRegisterModal: Dispatch<React.SetStateAction<boolean>>
}

const validateEmail = (email: string) => {
  const re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
  // const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
  return re.test(email)
}

const forgotModalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '560px',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const Login = () => {
  const location = useLocation()
  const [isRegisterModal, setIsRegisterModal] = useState(false)

  const navigate = useNavigate()

  const { t } = useTranslation()

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [checkedRemember, setCheckedRemember] = useState<boolean>(
    localStorageAPI.getItem('isUserRemember') === 'true' ? true : false
  )
  const [isForgotModalOpen, setIsForgotModalOpen] = useState(false)
  const [isApplyToRegisterModalOpen, setIsApplyToRegisterModalOpen] = useState(false)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)

  const trans = useTranslation()

  let postFlag = true

  const setLang = useLang()

  useEffect(() => {
    setLang()
  }, [])

  useEffect(() => {
    if (checkedRemember) {
      setUsername(localStorageAPI.getItem('username'))
      // setPassword(localStorageAPI.getItem('password'))
    }
  }, [])

  useEffect(() => {
    if (checkedRemember) {
      localStorageAPI.setItem('isUserRemember', 'true')
      return
    }
    localStorageAPI.setItem('isUserRemember', 'false')
  }, [checkedRemember])

  const handleForgotModalOpen = () => {
    setIsForgotModalOpen(true)
  }

  const handleForgotModalClose = () => {
    setIsForgotModalOpen(false)
  }

  const handleLoginButton = useCallback(async () => {
    if (!postFlag) {
      return
    }
    postFlag = false
    try {
      // const {
      //   data: { token },
      // } = await httpPostLogin({
      //   username: username,
      //   password,
      // })

      // const innerString = '더 좋은 서비스로 다시 찾아오겠습니다.'
      // setToastModal({ ...toastModal, isOpen: true, innerString: innerString })
      // return
      const promised: any = await httpPostLogin({
        username: username,
        password,
      })

      if (promised.response?.status === 400) {
        let innerString = ''
        switch (promised.response.data.error) {
          case 'bad credentials':
            innerString = '비밀번호가 틀립니다.'
            break
          case 'username not found':
            innerString = '등록되지 않은 이메일입니다.'
            break
          default:
            innerString = '로그인 중 오류가 발생했습니다.'
        }
        setToastModal({ ...toastModal, isOpen: true, innerString: innerString })
        return
      }

      const token = promised.data.token

      if (checkedRemember) {
        localStorageAPI.setItem('username', username)
        // localStorageAPI.setItem('password', password)
      }
      if (!checkedRemember) {
        localStorageAPI.removeItem('username')
        // localStorageAPI.removeItem('password')
      }
      if (token) sessionStorageAPI.setItem('token', token)
      navigate('/')
    } catch (error) {
      setToastModal({ ...toastModal, isOpen: true, innerString: LOGIN.fail })
      console.error(error)
      // alert(LOGIN.fail)
    } finally {
      postFlag = true
    }
  }, [username, password, checkedRemember])

  interface ForgotModalProps {
    isForgotModalOpen: boolean
    setIsForgotModalOpen: Dispatch<React.SetStateAction<boolean>>
  }

  // 안내 텍스트

  // 비밀번호 조기화

  // 가입한 이메일(ID)을 사용하여 비밀번호 초기화 요청 메일을 보내주시면
  // 확인 후 임시비밀번호를 보내드립니다.

  // 발신자 : 가입한 이메일 계정
  // 수신자 : help@directional.net (복사기능)

  const ApplyToRegisterModal = ({
    isApplyToRegisterModalOpen,
    setIsApplyToRegisterModalOpen,
    setIsRegisterModal,
  }) => {
    const [email, setEmail] = useState<string>('')
    const [isToastModalOpen, setIsToastModalOpen] = useState(false)
    const handleSubmit = useCallback(async () => {
      if (!validateEmail(email)) {
        setIsToastModalOpen(true)
        return
      }
      try {
        await httpPostSubmit(email)
        setIsRegisterModal(true)
        setIsApplyToRegisterModalOpen(false)
      } catch {
        alert(trans.t('modal.request.fail'))
      }
    }, [email])

    const timerList = []

    const ToastModal = ({ isToastModalOpen, setIsToastModalOpen, timerList }) => {
      useEffect(() => {
        timerList.map((_timer) => {
          clearTimeout(_timer)
        })
        if (isToastModalOpen === true) {
          const timer = setTimeout(() => {
            setIsToastModalOpen(false)
            timerList = timerList.filter((_timer) => _timer !== timer)
          }, toastModal.openTime)
          timerList.push(timer)
        }
      }, [toastModal])
      return (
        <div className={isToastModalOpen ? 'notice-wrap' : 'notice-wrap-none'}>
          <div className={'notice-over-amount'}>{trans.t('modal.valueCheck.emailInvalid')}</div>
        </div>
      )
    }

    return (
      <Modal
        open={isApplyToRegisterModalOpen}
        onClose={() => setIsApplyToRegisterModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...forgotModalStyle,
            width: '500px',
            paddingTop: '50px',
            paddingBottom: '50px',
          }}
        >
          <ToastModal
            isToastModalOpen={isToastModalOpen}
            setIsToastModalOpen={setIsToastModalOpen}
            timerList={timerList}
          ></ToastModal>
          <div className="register-form">
            <TextField
              placeholder="Enter corporate email"
              // value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="register-btn-wrap">
              <Button className="register-btn" onClick={() => handleSubmit()}>
                Submit
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    )
  }

  const ForgotModal = ({ isForgotModalOpen, setIsForgotModalOpen }: ForgotModalProps) => {
    return (
      <Modal
        open={isForgotModalOpen}
        onClose={() => setIsForgotModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...forgotModalStyle,
            width: '500px',
            height: '350px',
            paddingTop: '50px',
            paddingBottom: '50px',
          }}
        >
          <div id="parent-modal-title">비밀번호 초기화</div>
          <p id="parent-modal-description">
            {MESSAGE.forgotPassword}
            <div id="help-link">
              <a href="mailto:help@sblportal.com">help@sblportal.com</a>
            </div>
          </p>
          <hr className="en-kr" />
          <div id="parent-modal-title">Reset Password</div>
          <p id="parent-modal-description">
            {MESSAGE.forgotPasswordEnglish}
            <div id="help-link">
              <a href="mailto:help@sblportal.com">help@sblportal.com</a>
            </div>
          </p>
        </Box>
      </Modal>
    )
  }

  const RegisterModal = ({ isRegisterModal, setIsRegisterModal }: RegisterModalProps) => {
    return (
      <Modal
        open={isRegisterModal}
        onClose={() => setIsRegisterModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...forgotModalStyle,
            width: '500px',
            height: '350px',
            paddingTop: '50px',
            paddingBottom: '50px',
          }}
        >
          <div id="parent-modal-title">{trans.t('modal.result.succeed')}</div>
          <p id="parent-modal-description">
            기관투자자 전용 서비스입니다. 금융회사 이메일로 신청해주시면 가입 신청을
            도와드리겠습니다.
          </p>
          <hr className="en-kr" />
          <div id="parent-modal-title">Your submission was successful.</div>
          <p id="parent-modal-description">{MESSAGE.applyToRegister}</p>
          <br></br>
          <br></br>
          <div
            id="help-link"
            onClick={() => {
              setIsRegisterModal(false)
            }}
          >
            <a>OK</a>
          </div>
        </Box>
      </Modal>
    )
  }

  return (
    <>
      <div>
        <RegisterModal
          isRegisterModal={isRegisterModal}
          setIsRegisterModal={setIsRegisterModal}
        ></RegisterModal>
        <ApplyToRegisterModal
          isApplyToRegisterModalOpen={isApplyToRegisterModalOpen}
          setIsApplyToRegisterModalOpen={setIsApplyToRegisterModalOpen}
          setIsRegisterModal={setIsRegisterModal}
        ></ApplyToRegisterModal>
        {/*<div className="sidebar"></div>*/}
        <div className="overlay">
          <section className="user">
            <article className="login">
              <img src={require('@src/assets/login_logo.png')} alt="logo" />
              <p>Providing easy and secure stock loan access to over 100 institutional investors</p>
              <Form>
                <TextField
                  id="username"
                  label="Email"
                  type="text"
                  position="top"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  position="top"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e: any) => e.key === 'Enter' && handleLoginButton()}
                />
                <Checkbox
                  position="right"
                  label="Remember me"
                  id="remember"
                  checked={checkedRemember}
                  onChange={(e) => setCheckedRemember(e.target.checked)}
                />
                <div className="forgot-button-wrap">
                  {/* <Button
                    className="forgot-pw"
                    onClick={handleForgotModalOpen} */}
                  {/* // variant="text" // sx=
                  {{ color: '#1976d2 !important', textTransform: 'none', marginTop: '10px' }} */}
                  {/* > */}
                  <Button className="forgot-pw" onClick={handleLoginButton}>
                    Forgot password?
                  </Button>
                </div>
                <div className="button-wrapper">
                  <Button onClick={handleLoginButton} className="login">
                    Sign in
                  </Button>
                  <ForgotModal
                    isForgotModalOpen={isForgotModalOpen}
                    setIsForgotModalOpen={setIsForgotModalOpen}
                  ></ForgotModal>
                  {/* <Button
            radius
            className="join"
            style={{
              backgroundColor: '#3a3a3a',
            }}
            onClick={() => navigate('/user/join')}
          >
            Join
          </Button> */}
                </div>
                {/* <p>Contact us to register</p>
        <b>b2b@directional.io</b> */}
              </Form>

              <footer>
                {/*<img src={require('@src/assets/login_financial.png')} alt="" />*/}
                {/*<img src={require('@src/assets/logo.png')} alt="" />*/}
                {/*<img src={require('@src/assets/login_shinhan.png')} alt="" />*/}
                {/*<img src={require('@src/assets/koscom_cloud.png')} alt="" />*/}
              </footer>
            </article>
            <article
              className="main"
              // style={{
              //   backgroundImage: `url(${require('@src/assets/login_background.png')})`,
              // }}
            >
              <div className="register-form">
                {/* <Button onClick={() => setIsApplyToRegisterModalOpen(true)}> */}
                <Button onClick={handleLoginButton}>Apply to Register</Button>
              </div>
              <div className="footer-wrap">
                <div className="main-footer">
                  <div>Financial service provider licensed by</div>
                  <img src={require('@src/assets/login_financial.png')} alt="" />
                </div>
                <div className="main-footer login-footer">
                  <div>Partnered with</div>
                  <img src={require('@src/assets/login_shinhan.png')} alt="" />
                  <img src={require('@src/assets/koscom_cloud.png')} alt="" />
                </div>
              </div>
            </article>
            <Outlet />
          </section>
        </div>
      </div>
    </>
  )
}

export default Login
