// import { getFcmToken } from '@src/util/fcm'
import { useCallback } from 'react'
import { getMessaging, onMessage } from 'firebase/messaging'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { topNotiCountsState } from '@src/stores/topNotiCountsState'
import { httpGetTopNotiCounts } from '@src/api/httpAPI/httpNotiCountApi'
import alarmAudio from '@src/assets/alarm.mp3'
import { succeedModalState } from '@src/stores/succeedModalState'
import { topBlinkState, TopBlinkState } from '@src/stores/topBlinkState'

const getTopBlink = (
  topNotiCounts: TopNotiCounts,
  newNotiCounts: TopNotiCounts,
  prevTopBlink: TopBlinkState
) => {
  const topBlinkState: TopBlinkState = {
    borrow: {
      requestCount: prevTopBlink.borrow.requestCount
        ? true
        : newNotiCounts.borrow.requestCount > topNotiCounts.borrow.requestCount
        ? true
        : false,
      cancelRequestCount: prevTopBlink.borrow.cancelRequestCount
        ? true
        : newNotiCounts.borrow.cancelRequestCount > topNotiCounts.borrow.cancelRequestCount
        ? true
        : false,
      matchedCount: prevTopBlink.borrow.matchedCount
        ? true
        : newNotiCounts.borrow.matchedCount > topNotiCounts.borrow.matchedCount
        ? true
        : false,
    },
    lend: {
      requestCount: prevTopBlink.lend.requestCount
        ? true
        : newNotiCounts.lend.requestCount > topNotiCounts.lend.requestCount
        ? true
        : false,
      cancelRequestCount: prevTopBlink.lend.cancelRequestCount
        ? true
        : newNotiCounts.lend.cancelRequestCount > topNotiCounts.lend.cancelRequestCount
        ? true
        : false,
      matchedCount: prevTopBlink.lend.matchedCount
        ? true
        : newNotiCounts.lend.matchedCount > topNotiCounts.lend.matchedCount
        ? true
        : false,
    },
    recallCount: prevTopBlink.recallCount
      ? true
      : newNotiCounts.recallCount > topNotiCounts.recallCount
      ? true
      : false,
    urgentCount: prevTopBlink.urgentCount
      ? true
      : newNotiCounts.urgentCount > topNotiCounts.urgentCount
      ? true
      : false,
  }
  return topBlinkState
}

const useFCM = () => {
  const receiveMessage = {}
  // const [succeedModal, setSucceedModal] = useRecoilState(succeedModalState)
  // const setTopNotiCounts = useSetRecoilState(topNotiCountsState)
  // const setTopBlink = useSetRecoilState(topBlinkState)
  // const receiveMessage = async (topNotiCounts: TopNotiCounts, topBlink: TopBlinkState) => {
  //   const messaging = getMessaging()
  //   onMessage(messaging, async (payload) => {
  //     const message = { payload }
  //     console.log('fcm message:' + message)
  //     const alarm: HTMLAudioElement = document.querySelector('.fcm-alarm')
  //     const promise = alarm.play()
  //     // if (promise !== undefined) {
  //     //   promise
  //     //     .then((_) => {})
  //     //     .catch((error) => {
  //     //       setSucceedModal({
  //     //         ...succeedModal,
  //     //         isOpen: true,
  //     //         innerString: succeedModalInnerString + payload.notification.title + '/',
  //     //       })
  //     //     })
  //     // }
  //     const newNotiCounts = await httpGetTopNotiCounts()
  //     const newBlink = getTopBlink(topNotiCounts, newNotiCounts, topBlink)
  //     setTopBlink(newBlink)
  //     setTopNotiCounts(newNotiCounts)
  //   })
  //   // onBackgroundMessage(messaging, async (payload) => {
  //   //   console.log('[firebase-messaging-sw.js] Received background message ', payload)
  //   //   // Customize notification here
  //   //   console.log('fcm background message:' + payload)
  //   //   const audioElement: HTMLAudioElement = document.querySelector('.fcm-alarm')
  //   //   audioElement.play()
  //   //   const notificationTitle = 'Background Message Title'
  //   //   const notificationOptions = {
  //   //     body: 'Background Message body.',
  //   //     icon: '/firebase-logo.png',
  //   //   }
  //   // self.registration.showNotification(notificationTitle, notificationOptions)
  //   // })
  // }
  // return {
  //   receiveMessage,
  // }
  return { receiveMessage }
}

export default useFCM
