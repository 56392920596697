import Checkbox from '@src/components/Checkbox'
import Popup from '@src/components/PopupState/PopupState'
import TextField from '@src/components/TextField'
import { OrderStatusText } from '@src/constants/OrderStatusText'
import { OrderTypeText, OrderTypeTextEn } from '@src/constants/OrderTypeText'
import { RequestTypeText } from '@src/constants/RequestTypeText'
import { TransactionTypeText, TransactionTypeTextEn } from '@src/constants/TransactionTypeText'
import useDebounce from '@src/hooks/useDebounce'
import { formatNumber } from '@src/util/formatNumber'
import React, { Dispatch, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { QueryPayload } from './LendOrderResponse'
import {
  isRateInputCanBeOnChange,
  isVolumeInputCanBeOnChange,
} from '@src/util/input-limit/isRateInputLimit3digitsCheck'
import { getResponseStatus } from '@src/util/getResponseStatus'
import PopupTwoValue from '@src/components/PopupState/PopupTwoValue'
import { CLASSES } from '@src/constants/Global'
import { VALUECHECK } from '@src/constants/NotiText'
import useVolumeFormat from '@src/hooks/useVolumeFormat'
import { rateCheck, rateCheckWithValue } from '@src/util/rateCheck'
import parseNumberWithComma from '@src/util/parseNumComma'
import parseNumComma from '@src/util/parseNumComma'
import { getFormattedFixedRate } from '@src/util/getFormattedFixedRate'
import { useRecoilState, useRecoilValue } from 'recoil'
import { overAmountModalState } from '@src/stores/overAmountModalState'
import { forceRefreshState } from '@src/stores/forceRefreshState'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

interface Props {
  setSelectedRequestIds: React.Dispatch<React.SetStateAction<number[]>>
  requests: RequestV2Type[]
  setRequests: React.Dispatch<React.SetStateAction<RequestV2Type[]>>
  selectedIndex: number[]
  setSelectedIndex: React.Dispatch<React.SetStateAction<number[]>>
  queryPayload: QueryPayload
  setQueryPayload: React.Dispatch<React.SetStateAction<QueryPayload>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  valueChangedReq: RequestV2Type[]
  setValueChangedReq: React.Dispatch<React.SetStateAction<RequestV2Type[]>>
  resetInputFlag: boolean
  setResetInputFlag: React.Dispatch<React.SetStateAction<boolean>>
}

const LendOrderResponseTable: FunctionComponent<Props> = (props) => {
  const [selectedIndex, setSelectedIndex] = [props.selectedIndex, props.setSelectedIndex]
  const forceRefresh = useRecoilValue(forceRefreshState)
  const trans = useTranslation()

  const volumeFormat = useVolumeFormat()
  useEffect(() => {
    props.setSelectedRequestIds(
      props.requests
        .filter((_, index) => selectedIndex.includes(index))
        .map(({ requestId }) => requestId)
    )
  }, [selectedIndex])

  const tableDivRef = useRef<HTMLTableElement>(null)

  useEffect(() => {
    tableDivRef.current.scrollTop = -200
  }, [forceRefresh])

  const handlePopupMenuClick = (
    popupMenuValue: string,
    queryKey: string,
    popupClose: () => void
  ) => {
    const newPayLoad = { ...props.queryPayload }
    newPayLoad[queryKey] = popupMenuValue
    props.setQueryPayload(newPayLoad)
    props.setPage(0)
    popupClose()
  }

  const handlePopupTwoValueMenuClick = (
    popupMenuValue: string[],
    queryKey: string[],
    popupClose: () => void
  ) => {
    const newPayLoad = { ...props.queryPayload }
    for (let i = 0; i < popupMenuValue.length; i++) {
      newPayLoad[queryKey[i]] = popupMenuValue[i]
    }
    props.setQueryPayload(newPayLoad)
    props.setPage(0)
    popupClose()
  }

  return (
    <div className="fixed-table LEND lend-response" ref={tableDivRef}>
      <table>
        <thead>
          <tr>
            <th>
              <Checkbox
                readOnly
                checked={
                  selectedIndex.length !== 0 && selectedIndex.length == props.requests.length
                }
                onClick={() => {
                  if (selectedIndex.length == props.requests.length) {
                    setSelectedIndex([])
                  } else {
                    setSelectedIndex(props.requests.map((_, index) => index))
                  }
                }}
              />
            </th>
            <th>{trans.t('lendListTable.orderType')}</th>
            <th className={'lend-th-change'}>
              <Popup
                visibleText={trans.t('lendListTable.settlementType')}
                menuItems={[
                  {
                    text: trans.t('lendListTable.all'),
                    value: '',
                  },
                  {
                    text: 'T',
                    value: 'TODAY',
                  },
                  {
                    text: 'T1',
                    value: 'NEXT_DAY',
                  },
                ]}
                queryKey="settlementType"
                handlePopupMenuClick={handlePopupMenuClick}
              ></Popup>
            </th>
            <th className={'lend-th-change'}>
              <Popup
                visibleText={trans.t('lendListTable.tradeType')}
                menuItems={[
                  {
                    text: trans.t('lendListTable.all'),
                    value: '',
                  },
                  {
                    text: trans.t('lendListTable.innerTradeType.designated'),
                    value: 'DESIGNATED',
                  },
                  {
                    text: trans.t('lendListTable.innerTradeType.custom'),
                    value: 'CUSTOM',
                  },
                  {
                    text: trans.t('lendListTable.innerTradeType.none'),
                    value: 'NONE',
                  },
                ]}
                queryKey="transactionType"
                handlePopupMenuClick={handlePopupMenuClick}
              ></Popup>
            </th>
            <th className={'lend-th-change'}>
              <PopupTwoValue
                visibleText={trans.t('lendListTable.poolStatus')}
                menuItems={[
                  {
                    text: trans.t('lendListTable.all'),
                    value: ['ACCEPT,UPDATE', 'RECEIVED'],
                  },
                  {
                    text: trans.t('tradeRequested'),
                    value: ['ACCEPT', 'RECEIVED'],
                  },
                  {
                    text: trans.t('updateRequested'),
                    value: ['UPDATE', 'RECEIVED'],
                  },
                ]}
                queryKey={['requestType', 'requestStatus']}
                handlePopupTwoValueMenuClick={handlePopupTwoValueMenuClick}
              ></PopupTwoValue>
            </th>
            <th>{trans.t('partnerAsset')}</th>
            <th>{trans.t('issueCode')}</th>
            <th>{trans.t('issueName')}</th>
            <th>{trans.t('lendListTable.lendResponseDesiredVolume')}</th>
            {trans.i18n.language === 'en' ? (
              <th>
                {trans.t('lendListTable.lendResponseDesiredRate1')} <br />
                {trans.t('lendListTable.lendResponseDesiredRate2')}
              </th>
            ) : (
              <th>{trans.t('lendListTable.lendResponseDesiredRate')}</th>
            )}
            <th>
              {trans.t('lendListTable.poolRemainVolumeButton1')}
              <br></br>
              {trans.t('lendListTable.poolRemainVolumeButton2')}
            </th>
            <th>
              {trans.t('borrowListTable.borrowerVolumeButton1')}
              <br></br>
              {trans.t('borrowListTable.borrowerVolumeButton2')}
            </th>
            <th>
              {trans.i18n.language === 'ko' && (
                <>
                  {trans.t('borrowListTable.borrowerBidRateButton1')}
                  <br></br>
                  {trans.t('borrowListTable.borrowerBidRateButton2')}
                </>
              )}
              {trans.i18n.language === 'en' && (
                <>
                  {trans.t('borrowListTable.borrowerBidRateButton1')}
                  <br></br>
                  {trans.t('borrowListTable.borrowerBidRateButton2')}
                </>
              )}
            </th>
            {trans.i18n.language === 'en' ? (
              <th>
                {trans.t('updateVolume1')} <br />
                {trans.t('updateVolume2')}
              </th>
            ) : (
              <th>{trans.t('updateVolume')}</th>
            )}
            {trans.i18n.language === 'en' ? (
              <th>
                {trans.t('rateUpdate1')} <br />
                {trans.t('rateUpdate2')}
              </th>
            ) : (
              <th>{trans.t('updateVolume')}</th>
            )}
            <th>{trans.t('fixedAmount')}</th>
            <th className={'lend-th-change'}>
              <Popup
                visibleText={trans.t('lendListTable.swap')}
                menuItems={[
                  {
                    text: trans.t('lendListTable.all'),
                    value: '',
                  },
                  {
                    text: 'Y',
                    value: 'true',
                  },
                  {
                    text: 'N',
                    value: 'false',
                  },
                ]}
                queryKey="isSwap"
                handlePopupMenuClick={handlePopupMenuClick}
              ></Popup>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.requests.map((request, index) => (
            <TableRow
              key={`${request.stock.issueCode}-${index}`}
              data={request}
              setData={props.setRequests}
              index={index}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              valueChangedReq={props.valueChangedReq}
              setValueChangedReq={props.setValueChangedReq}
              resetInputFlag={props.resetInputFlag}
            />
          ))}
          <div className="table-bottom"></div>
        </tbody>
      </table>
    </div>
  )
}

const ContverTrueFalseToYorN = (bool: boolean) => {
  return bool ? 'Y' : 'N'
}

const TableRow: FunctionComponent<{
  index: number
  data: RequestV2Type
  setData: React.Dispatch<React.SetStateAction<RequestV2Type[]>>
  selectedIndex: number[]
  setSelectedIndex: React.Dispatch<React.SetStateAction<number[]>>
  valueChangedReq: RequestV2Type[]
  setValueChangedReq: React.Dispatch<React.SetStateAction<RequestV2Type[]>>
  resetInputFlag: boolean
}> = ({
  data,
  index,
  selectedIndex,
  setSelectedIndex,
  setData,
  valueChangedReq,
  setValueChangedReq,
  resetInputFlag,
}) => {
  const [volume, setVolume] = useState<string>(String(data.requestedVolume))
  const [rate, setRate] = useState<string>(data.requestedRate?.toFixed(2))
  const [currentVolume, setCurrentVolume] = useState('0')
  const [currentRate, setCurrentRate] = useState('')
  const [inputColor, setInputColor] = useState('#ffffff')

  const [overAmountModal, setOverAmountModal] = useRecoilState(overAmountModalState)

  const debouncedRate = useDebounce(rate, 1000)
  const volumeFormat = useVolumeFormat()
  const trans = useTranslation()

  useEffect(() => {
    setCurrentVolume('0')
    setCurrentRate('')
  }, [resetInputFlag])

  const changeTableInput = useCallback(
    (newData: Partial<RequestV2Type>) => {
      setData((prevData) => [
        ...prevData.slice(0, index),
        {
          ...data,
          ...newData,
        },
        ...prevData.slice(index + 1),
      ])
    },
    [setData, data, index]
  )

  useEffect(() => {
    if (!data.stock.issueCode || rate == null) return
    // if (Number(debouncedRate) >= 1000) {
    //   alert('요율은 3자리까지 입력이 가능합니다.')lo
    //   setRate(data.rate || 0)
    //   return
    // }
    const fixedRate = Number(rate.replaceAll(',', '')).toFixed(2)

    changeTableInput({
      newRequestedRate: fixedRate,
    })
    // setRate(fixedRate)
  }, [rate])

  useEffect(() => {
    // if (!data.stock.issueCode) return

    if (String(volume).length > 8) {
      alert(trans.t('modal.valueCheck.upToEightCharacters'))
      return
    }

    changeTableInput({
      newRequestedVolume: volume,
    })
  }, [volume])

  const handleRowClick = useCallback(
    (checked: boolean) => {
      if (!checked) {
        const _volume = data.requestedVolume ? data.requestedVolume : data.currentVolume
        const volume = String(_volume)
        const _rate = data.requestedRate ? data.requestedRate : data.currentRate
        const rate = String(_rate)
        if (currentVolume === '0') {
          const currentValue = volume.replaceAll(',', '')
          const newValue = volumeFormat(currentValue, ',')
          setCurrentVolume(newValue)
          setVolume(newValue.replaceAll(',', ''))
        }
        if (currentRate === '') {
          const newValue = rateCheckWithValue(Number(rate).toFixed(2), volumeFormat)
          if (!newValue) {
            return
          }
          setCurrentRate(newValue)
          setRate(newValue.replaceAll(',', ''))
        }
        if (
          Number(currentVolume) === data.currentVolume &&
          currentRate === data.currentRate.toFixed(2)
        ) {
          setValueChangedReq(valueChangedReq.filter((req) => req.requestId !== data.requestId))
        }
        if (currentVolume === '0' && currentRate === '') {
          setValueChangedReq(valueChangedReq.filter((req) => req.requestId !== data.requestId))
        }
      } else {
        setCurrentVolume('0')
        setCurrentRate('')
        setInputColor('#ffffff')
      }
    },
    [currentVolume, currentRate, valueChangedReq]
  )

  const getFixedAmountCellInner = (request: RequestV2Type, currentVolume: number) => {
    if (request.requestType === 'ACCEPT') {
      if (isNaN(currentVolume * request.stock.tradePrice)) {
        return 0
      }
      return parseNumberWithComma(currentVolume * request.stock.tradePrice)
    }
    if (request.requestType === 'UPDATE') {
      if (isNaN(request.requestedVolume * request.stock.tradePrice)) {
        return 0
      }
      return parseNumberWithComma(request.requestedVolume * request.stock.tradePrice)
    }
    return 0
  }

  return (
    <tr
      onClick={() => {
        const checked = selectedIndex.includes(index)
        if (!checked) {
          setSelectedIndex((selectedIndex) => [...selectedIndex, index])
        } else {
          setSelectedIndex((selectedIndex) => selectedIndex.filter((_index) => _index !== index))
        }
        handleRowClick(checked)
      }}
    >
      <td>
        <Checkbox readOnly checked={selectedIndex.includes(index)} />
      </td>
      {/* <td>{OrderStatusText[data.orderType]}</td> */}

      <td>
        {localStorageAPI.getItem('lang') === 'en'
          ? OrderTypeTextEn[data.orderType]
          : OrderTypeText[data.orderType]}
      </td>
      <td className={CLASSES.tableCellFilterable}>{data.settlementType == 'TODAY' ? 'T' : 'T1'}</td>
      <td className={CLASSES.tableCellFilterable}>
        {localStorageAPI.getItem('lang') === 'en'
          ? TransactionTypeTextEn[data.transactionType]
          : TransactionTypeText[data.transactionType]}
      </td>
      <td className={CLASSES.tableCellFilterable}>
        {getResponseStatus(data.requestStatus, data.requestType, localStorageAPI.getItem('lang'))}
      </td>
      <td className={data.isSwap && 'swap-highlight-cell'}>
        {localStorageAPI.getItem('lang') === 'en'
          ? data.partnerAccount.companyEnglishName
          : data.partnerAccount.companyName}
      </td>
      <td>{data.stock.issueCode}</td>
      <td>
        {localStorageAPI.getItem('lang') === 'en'
          ? data.stock.issueEnglishName
          : data.stock.issueName}
      </td>
      <td className="right">{formatNumber(data.poolVolume)}</td>
      <td className="right">{data.poolRate?.toFixed(2)}</td>
      <td className="right">{formatNumber(data.remainVolume)}</td>
      <td className="right">{formatNumber(data.currentVolume)}</td>
      <td className={data.currentRate !== data.poolRate ? 'right rate-highlight-cell' : 'right'}>
        {data.currentRate?.toFixed(2)}
      </td>
      <td className="right">
        {data.requestType === 'ACCEPT' && (
          <input
            className="input-text-align-right"
            style={{ color: inputColor }}
            maxLength={15}
            value={currentVolume}
            onClick={(e) => {
              e.stopPropagation()
            }}
            type="string"
            onKeyDown={(e: any) => {
              const start = e.target.selectionStart
              const end = e.target.selectionEnd
              const isLengthOne = start === end
              if (e.key === 'Delete' && e.target.value[start] === ',' && isLengthOne) {
                e.target.setSelectionRange(start + 1, start + 1)
                e.preventDefault()
                return
              }
            }}
            onFocus={(e) => {
              if (e.target.value === '0') {
                const newVlaue = volumeFormat(String(data.currentVolume))
                setCurrentVolume(newVlaue)
                e.target.value = newVlaue
                setVolume(newVlaue.replaceAll(',', ''))
                setInputColor('#000000')
                e.target.select()
                return
              }
              setInputColor('#000000')
              e.target.select()
              setCurrentVolume(e.target.value)
            }}
            onChange={(e) => {
              if (e.target.value.length > 10) {
                return
              }
              let currentValue = e.target.value.replaceAll(',', '')
              if (currentValue[0] === '0') {
                currentValue = currentValue.substring(1, currentValue.length)
              }
              let newValue = volumeFormat(currentValue, ',')
              if (currentValue > data.poolVolume) {
                setOverAmountModal({
                  ...overAmountModal,
                  maxVolume: data.poolVolume,
                  isOpen: true,
                })
                newValue = volumeFormat(String(data.poolVolume))
              }
              let prevCommaCount = currentVolume.split(',').length - 1
              prevCommaCount = prevCommaCount < 0 ? 0 : prevCommaCount
              const start = e.target.selectionStart
              let nextCommaCount = newValue.split(',').length - 1
              nextCommaCount = nextCommaCount < 0 ? 0 : nextCommaCount
              const commaDiff = nextCommaCount - prevCommaCount
              e.target.value = newValue
              e.target.setSelectionRange(start + commaDiff, start + commaDiff)
              if (e.target.value.length < 2) {
                e.target.setSelectionRange(1, 1)
              }
              setCurrentVolume(newValue)
              setVolume(newValue.replaceAll(',', ''))
              if (parseInt(e.target.value.replaceAll(',', '')) !== data.currentVolume) {
                setInputColor('#000000')
                if (!valueChangedReq.find((req) => req.requestId === data.requestId)) {
                  setValueChangedReq([...valueChangedReq, data])
                  return
                }
              } else {
                if (parseFloat(currentRate).toFixed(2) === data.currentRate.toFixed(2)) {
                  setValueChangedReq(
                    valueChangedReq.filter((req) => req.requestId !== data.requestId)
                  )
                }
              }
            }}
          ></input>
        )}
        {data.requestType === 'UPDATE' && formatNumber(data.requestedVolume)}
      </td>
      <td className="right">
        {data.requestType === 'ACCEPT' && (
          <input
            className="input-text-align-right"
            style={{ color: inputColor }}
            maxLength={9}
            value={currentRate}
            onClick={(e) => {
              e.stopPropagation()
            }}
            type="text"
            onKeyDown={(e: any) => {
              const start = e.target.selectionStart
              const end = e.target.selectionEnd
              const isLengthOne = start === end
              if (e.key === 'Delete' && e.target.value[start] === ',' && isLengthOne) {
                e.target.setSelectionRange(start + 1, start + 1)
                e.preventDefault()
                return
              }
              if (e.key === 'Enter') {
                if (e.target.value === '') {
                  return
                }
                if (e.target.value.indexOf('.') === -1) {
                  setCurrentRate(e.target.value + '.00')
                  return
                }
                const splited = e.target.value.split('.')
                if (splited[1].length === 0) {
                  setCurrentRate(e.target.value + '00')
                }
                if (splited[1].length === 1) {
                  setCurrentRate(e.target.value + '0')
                }
              }
            }}
            onBlur={(e) => {
              if (e.target.value === '') {
                return
              }
              if (e.target.value.indexOf('.') === -1) {
                setCurrentRate(e.target.value + '.00')
                return
              }
              const splited = e.target.value.split('.')
              if (splited[1].length === 0) {
                setCurrentRate(e.target.value + '00')
              }
              if (splited[1].length === 1) {
                setCurrentRate(e.target.value + '0')
              }
            }}
            onFocus={(e) => {
              setInputColor('#000000')
              if (e.target.value === '') {
                const newValue = getFormattedFixedRate(data.currentRate)
                e.target.value = newValue
                setCurrentRate(newValue.replaceAll(',', ''))
                setRate(newValue.replaceAll(',', ''))
                if (
                  parseFloat(e.target.value.replaceAll(',', '')).toFixed(2) !==
                  data.currentRate.toFixed(2)
                ) {
                  if (!valueChangedReq.find((req) => req.requestId === data.requestId)) {
                    setValueChangedReq([...valueChangedReq, data])
                    return
                  }
                } else {
                  if (currentVolume.replaceAll(',', '') === String(data.currentVolume)) {
                    setValueChangedReq(
                      valueChangedReq.filter((req) => req.requestId !== data.requestId)
                    )
                  }
                }
              }
              e.target.select()
            }}
            onChange={(e) => {
              if (e.target.value === '') {
                setCurrentRate('')
                setRate('')
                if (
                  parseFloat(e.target.value.replaceAll(',', '')).toFixed(2) !==
                  data.currentRate.toFixed(2)
                ) {
                  setInputColor('#000000')
                  if (!valueChangedReq.find((req) => req.requestId === data.requestId)) {
                    setValueChangedReq([...valueChangedReq, data])
                    return
                  }
                } else {
                  if (currentVolume.replaceAll(',', '') === String(data.currentVolume)) {
                    setValueChangedReq(
                      valueChangedReq.filter((req) => req.requestId !== data.requestId)
                    )
                  }
                }
                return
              }
              const newValue = rateCheck(e, volumeFormat)
              if (!newValue) {
                return
              }
              let prevCommaCount = currentRate.split(',').length - 1
              prevCommaCount = prevCommaCount < 0 ? 0 : prevCommaCount
              const start = e.target.selectionStart
              let nextCommaCount = newValue.split(',').length - 1
              nextCommaCount = nextCommaCount < 0 ? 0 : nextCommaCount
              const commaDiff = nextCommaCount - prevCommaCount
              e.target.value = newValue
              e.target.setSelectionRange(start + commaDiff, start + commaDiff)
              if (e.target.value.length < 2) {
                e.target.setSelectionRange(1, 1)
              }
              setCurrentRate(newValue)
              setRate(newValue.replaceAll(',', ''))
              if (
                parseFloat(e.target.value.replaceAll(',', '')).toFixed(2) !==
                data.currentRate.toFixed(2)
              ) {
                if (!valueChangedReq.find((req) => req.requestId === data.requestId)) {
                  setValueChangedReq([...valueChangedReq, data])
                  return
                }
              } else {
                if (currentVolume.replaceAll(',', '') === String(data.currentVolume)) {
                  setValueChangedReq(
                    valueChangedReq.filter((req) => req.requestId !== data.requestId)
                  )
                }
              }
            }}
          ></input>
        )}
        {data.requestType === 'UPDATE' && getFormattedFixedRate(data.requestedRate)}
      </td>
      <td className="right">
        {getFixedAmountCellInner(data, Number(currentVolume.replaceAll(',', '')))}
      </td>
      <td className={data.isSwap && 'swap-highlight-cell'}>
        {ContverTrueFalseToYorN(data.isSwap)}{' '}
      </td>
      <td></td>
      {/* <td>{formatNumber(data.</td> */}
    </tr>
  )
}

export default LendOrderResponseTable
