// import { httpPostMessageToken } from '@src/api/httpAPI/httpUserAPI'
// import { initializeApp } from 'firebase/app'
// import { getToken, getMessaging } from 'firebase/messaging'

// let isBlocked: boolean

// const isNotificationSupported = 'Notification' in window
// if (isNotificationSupported) {
//   Notification.requestPermission().then(function (result) {
//     if (result === 'granted') {
//       isBlocked = false
//       console.log('notification granted')
//     } else {
//       console.log('notification blocked')
//       isBlocked = true
//     }
//   })
// }

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('/firebase-messaging-sw.js')
//     .then(function (registration) {
//       console.log('Registration successful, scope is:', registration.scope)
//     })
//     .catch(function (err) {
//       console.log('Service worker registration failed, error:', err)
//     })
// }

// // TODO
// const firebaseApp = initializeApp({
//   apiKey: 'AIzaSyC__-RPzsC5qiuQ1cpGz9917QkH8EKc7xQ',
//   authDomain: 'directional-611fe.firebaseapp.com',
//   databaseURL: 'https://directional-611fe.firebase/io.com',
//   projectId: 'directional-611fe',
//   storageBucket: 'directional-611fe.appspot.com',
//   messagingSenderId: '686541668969',
//   appId: '1:686541668969:web:106556836c80bdc96598f8',
//   measurementId: 'G-measurement-id',
// })

// const messaging = isBlocked === false ? getMessaging() : null

export const getFcmToken = () => {
  // getToken(messaging, {
  //   vapidKey:
  //     'BE1zNrGuestspK9Em-4UJWurWbCkZlsfPOawTZ3iPvY5zQctC4QrF0LoXQEstkFw8tMAsGJNkYkx4RX9K6aqRGg',
  // })
  //   .then((currentToken) => {
  //     if (currentToken) {
  //       console.log('fcm token sended')
  //       httpPostMessageToken(currentToken)
  //     } else {
  //       console.log('No registration token available. Request permission to generate one.')
  //     }
  //   })
  //   .catch((err) => {
  //     console.error('An error occurred while retrieving token. ', err)
  //   })
}

// export default firebaseApp
