import { httpGetCompanies } from '@src/api/httpAPI/httpCompanyAPI'
import {
  httpGetUserCompanyList,
  httpGetUserGroup,
  httpPostUserGroup,
  httpPutUser,
} from '@src/api/httpAPI/httpUserAPI'
import Button from '@src/components/Button'
import LogoutButton from '@src/components/Button/LogoutButton'
import Checkbox from '@src/components/Checkbox'
import TextField from '@src/components/TextField'
import { userState } from '@src/stores/userStore'
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useRecoilState } from 'recoil'
import MyPagePasswordPopup from './MyPagePasswordPopup'
import AsyncSelect from 'react-select/async'
import TradeButton from '@src/components/Button/TradeButton'
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Box,
  IconButton,
  styled,
  TextField as MuiTextField,
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import httpClient from '@src/api/httpAPI/httpClient'
import { AxiosResponse } from 'axios'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AddIcon from '@mui/icons-material/Add'
import { Search as SearchIcon, Close as CloseIcon, ChildCare } from '@mui/icons-material'
import MyGroupTextFeild from '@src/components/TextField/MyGroupTextFiled'
import {
  httpDelMyGroupCompany,
  httpGetMyGroup,
  httpGetMyGroupList,
  httpPostAddCompanyToGroup,
  httpPostAddMyGroup,
} from '@src/api/httpAPI/httpMyPageAPI'
import { delConfirm } from '@src/api/httpAPI/confirmMsg'
import { AutocompleteInputChangeEvent } from './AutocompleteInputChangeEvent'
import { REQUEST, SAVE, VALUECHECK } from '@src/constants/NotiText'
import { confirmModalState } from '@src/stores/confirmModalState'
import { errorModalState } from '@src/stores/errorModalState'
import { toastModalState } from '@src/stores/toastModalState'
import { useTranslation } from 'react-i18next'
import { localStorageAPI } from '../../api/storageAPI'

interface Props {}

const groups = [{}, {}, {}, {}, {}]

export interface MyGroup {
  id: number
  name: string
}

interface MyGroupDetail {
  id: number
  name: string
  accountList: MyGroupCompnay[]
}
interface MyGroupCompnay {
  accountId: number
  companyCode: string
  companyName: string
  companyPropertyCode: string
  companySLBCode: string
}

const MyPage: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(userState)
  // const [userGroup, setUserGroup] = useState<UserGroupType[]>([])

  const [myGroupList, setMyGroupList] = useState<MyGroup[]>([])
  const [currentGroup, setCurrentGroup] = useState<MyGroupDetail>(null)

  const [name, setName] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [phoneCount, setPhoneCount] = useState<number>(1)
  const [phoneList, setPhoneList] = useState<Map<number, string>>(new Map())
  const [confirmationEmailCount, setConfirmationEmailCount] = useState<number>(0)
  const [confirmationEmailList, setConfirmationEmailList] = useState<Map<number, string>>(new Map())
  const [accountList, setAccountList] = useState<UserType['accountList']>([])
  const [account, setAccount] = useState<string>('')
  const [slbAccount, setSlbAccount] = useState<string>('')
  const [organization, setOrganization] = useState<string>('')
  const [memo, setMemo] = useState<string>('')

  const [autocompeteInputValue, setAutocompleteInputValue] = useState('')
  const [autocompeteValue, setAutocompleteValue] = useState({
    accountId: -1,
    companyName: '',
    label: '',
  })

  const [currentInputAccountId, setCurrentInputAccountId] = useState<number>(-1)

  const [companyList, setCompanyList] = useState([])
  const [companyFilter, setCompanyFilter] = useState('')

  const [editingMyGroupIndex, setEditingMyGroupIndex] = useState(-1)

  const [passwordPopupOpen, setPasswordPopupOpen] = useState<boolean>(false)

  const [selectedMyGroupId, setSelectedMyGroupId] = useState<number>(-1)
  const [searchHighlight, setSearchHighlight] = useState('')

  const [resetInputRefFlag, setResetInputRefFlag] = useState(false)

  const [confirmModal, setConfirmModal] = useRecoilState(confirmModalState)
  const [errorModal, setErrorModal] = useRecoilState(errorModalState)
  const [toastModal, setToastModal] = useRecoilState(toastModalState)

  const trans = useTranslation()

  // const getCompanyList = useCallback(async (query: string) => {
  //   const companyList = await httpGetCompanies({ query })
  //   return companyList
  // }, [])

  // const inputRef = useRef()
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {}, [resetInputRefFlag])

  const fetchMyGroupList = useCallback(async (id?: number) => {
    const data = await httpGetMyGroupList()
    // ref.current.value = ''
    // console.log('ref.current.value',ref.current.value)
    setMyGroupList(data)
    if (data.length === 0) {
      setSelectedMyGroupId(-1)
      return
    }
    const dataIds = data.map((data: any) => data.id)
    if (!dataIds.includes(id)) {
      setSelectedMyGroupId(-1)
      return
    }
  }, [])

  // useEffect(()=>{
  //   console.log('selectedMyGroupId',selectedMyGroupId)
  //   // if (selectedMyGroupId === -1) {
  //   //   setCurrentGroup({
  //   //     id: -1,
  //   //     name: '-1',
  //   //     accountList: []
  //   //   })
  //     // ref.current.value = ''
  //   // }
  //   ref.current.value = ''
  // },[selectedMyGroupId, ref])

  useEffect(() => {
    fetchMyGroupList()
  }, [])

  // useEffect(()=>{
  //   console.log('ref',ref)
  // },[ref])
  // ;async () => {
  //   if (window.confirm(delConfirm)) {
  //     await httpDelMyGroupCompany(selectedMyGroupId, group.accountId)
  //     await getCurrentGroup(selectedMyGroupId)
  //   }
  // }

  const handleDleBtnCallback = async (param) => {
    try {
      await httpDelMyGroupCompany(param.id, param.accountId)
      await getCurrentGroup(param.id)
      // await httpDelMyGroup(param.id)
      // await fetchMyGroupList(param.id)
      setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('deleted') })
    } catch (error) {
      setErrorModal({ ...errorModal, isOpen: true, innerString: trans.t('modal.request.invalid') })
      console.error(error)
      // alert(HttpErrMsgDefault)
    }
  }

  const handleDleBtn = useCallback(async (id: number, accountId: number) => {
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: trans.t('modal.request.removeCounterparty'),
      confirmFunction: handleDleBtnCallback,
      closeFunction: () => {
        return
      },
      confirmFunctionParam: { id: id, accountId: accountId },
      // if (window.confirm(delConfirm)) {
      //   try {
      //     await httpDelMyGroup(id)
      //     await fetchMyGroupList(id)
      //   } catch (e) {
      //     console.error(e)
      //     alert(HttpErrMsgDefault)
      //   }
      // }
    })
  }, [])

  const addAccount = useCallback(() => {
    if (account.length < 12) {
      setToastModal({
        ...toastModal,
        isOpen: true,
        innerString: trans.t('modal.valueCheck.need12Characters'),
      })
      // alert(trans.t('modal.valueCheck.need12Characters'))
      return
    }
    setAccountList((accountList) => [
      ...accountList,
      {
        isSelected: false,
        companyName: '',
        ksdAccount: account,
        memo,
        organization,
        slbAccount: slbAccount,
      },
    ])
    setAccount('')
    setSlbAccount('')
    setOrganization('')
    setMemo('')
  }, [account, slbAccount, organization, memo])

  const handleGroupAddBtn = useCallback(async () => {
    try {
      await httpPostAddMyGroup()
      await fetchMyGroupList()
      setCurrentGroup({
        id: -1,
        name: '-1',
        accountList: [],
      })
    } catch (e) {
      setToastModal({ ...toastModal, isOpen: true, innerString: trans.t('modal.request.invalid') })
      // alert(trans.t('modal.request.serverError'))
      console.error(e)
    }
  }, [])

  // const getUserGroup = useCallback(async () => {
  //   const userGroup = await httpGetUserGroup()
  //   setUserGroup(userGroup)
  // }, [])

  const getCurrentGroup = useCallback(async (id: number) => {
    const myGroup = await httpGetMyGroup(id)
    setCurrentGroup(myGroup)
  }, [])

  const saveUserCallback = async () => {
    try {
      const { data } = await httpPutUser({
        name,
        username,
        phoneList: Array.from(phoneList, ([_, phone]) => ({ phone })),
        accountList,
        confirmationEmailList: Array.from(confirmationEmailList, ([_, email]) => ({ email })),
      })
      setUser(data)
      setToastModal({ ...toastModal, isOpen: true, innerString: SAVE.succeed })
      // alert(SAVE.succeed)
    } catch {
      setErrorModal({ ...errorModal, isOpen: true, innerString: trans.t('modal.request.invalid') })
      // (SAVE.fail)
    }
  }

  const saveUser = async () => {
    const innserString =
      localStorageAPI.getItem('lang') === 'en' ? 'Save information?' : '저장하시겠습니까?'
    setConfirmModal({
      ...confirmModal,
      isOpen: true,
      innerString: innserString,
      confirmFunction: saveUserCallback,
      closeFunction: () => {
        return
      },
      // tab: tab,
    })
  }

  useEffect(() => {
    if (!user) return
    setName(user.name)
    setUsername(user.username)
    setPhoneList(
      user.phoneList.reduce((map, { phone }, index) => {
        map.set(index, phone)
        return map
      }, new Map())
    )
    setConfirmationEmailList(
      user.confirmationEmailList.reduce((map, { email }, index) => {
        map.set(index, email)
        return map
      }, new Map())
    )
    setConfirmationEmailCount(
      user.confirmationEmailList.length == 0 ? 1 : user.confirmationEmailList.length
    )

    setAccountList(user.accountList)
    // getUserGroup()
  }, [user])

  const fetchCompanies = async () => {
    const companies = await httpClient.get('/api/v1/accounts?query=')
    const result = companies.data.map(
      ({ accountId, companyName, companyCode, companyPropertyCode, companySLBCode }) => ({
        accountId: accountId,
        label: companyCode + '-' + companyPropertyCode + '-' + companySLBCode,
        companyName: companyName,
      })
    )
    setCompanyList(result)
  }

  useEffect(() => {
    fetchCompanies()
  }, [])

  const handleAutocompleteInputClose = useCallback(
    async (
      e: AutocompleteInputChangeEvent,
      reason: AutocompleteInputChangeReason,
      selectedMyGroupId: number
    ) => {
      // console.log('value',value)
      // if(value === '') {
      //   return
      // }
      if (!e) {
        return
      }
      if (e._reactName === undefined) {
        return
      }
      // if (e._reactName === 'onChange') {
      //   // setAutocompleteInputValue(value)
      //   return
      // }
      if (selectedMyGroupId === -1) {
        setToastModal({
          ...toastModal,
          isOpen: true,
          innerString: trans.t('modal.valueCheck.noGroup'),
        })
        // alert(trans.t('modal.valueCheck.noGroup'))
        return
      }
      if (e._reactName === 'keyDown') {
        try {
          await httpPostAddCompanyToGroup(selectedMyGroupId, { accountId: currentInputAccountId })
          await getCurrentGroup(selectedMyGroupId)
          return
        } catch (error) {
          setErrorModal({
            ...errorModal,
            isOpen: true,
            innerString: trans.t('modal.request.invalid'),
          })
          console.error(error)
          // alert(trans.t('modal.request.updateFail'))
          return
        }
      }
      // ref.current.value = ''
      // if (reason !== 'reset') {
      //   return
      // }
      // if(value === '') {
      //   return
      // }

      // // const accountId = currentInputAccountId
      // if (isNaN(currentInputAccountId)) {
      //   alert('계좌번호가 올바르지 않습니다.')
      //   return
      // }
      // setCurrentInputAccountId(currentInputAccountId)
      await httpPostAddCompanyToGroup(selectedMyGroupId, { accountId: currentInputAccountId })
      await getCurrentGroup(selectedMyGroupId)
      // // setAutocompleteInputValue('')
      // // setCompanyFilter('')
      // return
    },
    [currentInputAccountId, companyList, autocompeteInputValue]
  )

  const handleAutocompleteInputChange = useCallback(
    async (
      e: AutocompleteInputChangeEvent,
      value: string,
      reason: AutocompleteInputChangeReason,
      selectedMyGroupId: number
    ) => {
      if (value === '') {
        return
      }
      if (!e) {
        return
      }
      if (e._reactName === 'onChange') {
        // setAutocompleteInputValue(value)
        return
      }
      if (selectedMyGroupId === -1) {
        alert(trans.t('modal.valueCheck.noGroup'))
        return
      }
      if (e._reactName === 'keyDown') {
        try {
          await httpPostAddCompanyToGroup(selectedMyGroupId, { accountId: currentInputAccountId })
          await getCurrentGroup(selectedMyGroupId)
          return
        } catch (e) {
          alert(trans.t('modal.request.updateFail'))
          return
        }
      }
      // ref.current.value = ''
      // if (reason !== 'reset') {
      //   return
      // }
      // if(value === '') {
      //   return
      // }

      // // const accountId = currentInputAccountId
      // if (isNaN(currentInputAccountId)) {
      //   alert('계좌번호가 올바르지 않습니다.')
      //   return
      // }
      // setCurrentInputAccountId(currentInputAccountId)
      await httpPostAddCompanyToGroup(selectedMyGroupId, { accountId: currentInputAccountId })
      await getCurrentGroup(selectedMyGroupId)
      // // setAutocompleteInputValue('')
      // // setCompanyFilter('')
      // return
    },
    [currentInputAccountId, companyList, autocompeteInputValue]
  )

  const handleAutocompleteKeyDown = useCallback(
    async (e: any, selectedMyGroupId: number) => {
      if (e.key !== 'Enter' || e.key !== 'Tab') {
        return
      }
      if (selectedMyGroupId === -1) {
        // alert(trans.t('modal.valueCheck.noGroup'))
        setToastModal({
          ...toastModal,
          isOpen: true,
          innerString: trans.t('modal.valueCheck.noGroup'),
        })
        return
      }
      if (currentInputAccountId === -1) {
        // alert(trans.t('modal.valueCheck.invalidAccountNumber'))
        setToastModal({
          ...toastModal,
          isOpen: true,
          innerString: trans.t('modal.valueCheck.invalidAccountNumber'),
        })
        return
      }
      await httpPostAddCompanyToGroup(selectedMyGroupId, { accountId: currentInputAccountId })
      await getCurrentGroup(selectedMyGroupId)
      setAutocompleteInputValue('')
      setCompanyFilter('')
    },
    [currentInputAccountId]
  )

  let autoCompleteCloseFlag = false

  const autoCompleteCloseFlagCheck = () => {
    if (autoCompleteCloseFlag) {
      return true
    } else {
      autoCompleteCloseFlag = true
      return false
    }
  }

  return (
    <div className="mypage">
      <article>
        <div className="button-wrap">
          {/*<TradeButton color="red" onClick={() => navigate(-1)}>*/}
          {/*  뒤로가기*/}
          {/*</TradeButton>*/}
          <TradeButton color="green" onClick={() => saveUser()}>
            {trans.t('save')}
          </TradeButton>
        </div>
        <div className="user-info">
          <div className="account-info">
            <TextField
              id="name"
              label={trans.t('name')}
              type="text"
              position="top"
              placeholder={name}
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              id="username"
              label={trans.t('email')}
              type="text"
              position="top"
              placeholder={username}
              defaultValue={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <div className="set-pw" onClick={() => setPasswordPopupOpen(true)}>
              {trans.t('passwordChange')}
            </div>
          </div>
          <div className="account-info">
            {Array.from({ length: 2 }).map((_, index) => (
              <TextField
                key={`phone${index}`}
                id={`phone${index}`}
                label={index == 0 ? trans.t('phoneNo') : ''}
                type="text"
                position="top"
                defaultValue={phoneList.get(index)}
                onChange={(e) => {
                  const newMap = new Map(phoneList)
                  newMap.set(index, e.target.value)
                  setPhoneList(newMap)
                }}
              >
                {/* <>
                  {index > 0 && (
                    <span
                      className="del"
                      onClick={() => {
                        const newMap = new Map(phoneList)
                        newMap.delete(index)
                        setPhoneList(newMap)
                        setPhoneCount((prevPhoneCount) => --prevPhoneCount)
                      }}
                    >
                      삭제
                    </span>
                  )}
                  {phoneCount == index + 1 && index < 2 && (
                    <span
                      className="add"
                      onClick={() => setPhoneCount((prevPhoneCount) => ++prevPhoneCount)}
                    >
                      추가
                    </span>
                  )}
                </> */}
              </TextField>
            ))}
            {/* <TextField
              id="name"
              label="회사명"
              type="text"
              position="top"
              // value={username}
              // onChange={(e) => setUsername(e.target.value)}
            /> */}

            {Array.from({ length: confirmationEmailCount == 0 ? 1 : confirmationEmailCount }).map(
              (_, index) => {
                const sblEmail =
                  localStorageAPI.getItem('lang') === 'en'
                    ? 'Email for receiving SBL confirmation'
                    : 'SBL 컨퍼메이션 받을 이메일'
                return (
                  <TextField
                    // style={{
                    //   marginTop: index == 0 ? '10px' : '0',
                    //   marginBottom: 0,
                    // }}
                    key={`confirmation_email${index}`}
                    id={`confirmation_email${index}`}
                    label={index == 0 ? sblEmail : ''}
                    type="email"
                    position="top"
                    defaultValue={confirmationEmailList.get(index)}
                    onChange={(e) => {
                      const newMap = new Map(confirmationEmailList)
                      newMap.set(index, e.target.value)
                      setConfirmationEmailList(newMap)
                    }}
                  >
                    <>
                      {index > 0 && (
                        <span
                          className="del"
                          onClick={() => {
                            const newMap = new Map(confirmationEmailList)
                            newMap.delete(index)
                            setConfirmationEmailList(newMap)
                            setConfirmationEmailCount(
                              (prevConfirmationEmailCount) => --prevConfirmationEmailCount
                            )
                          }}
                        >
                          {trans.t('delete')}
                        </span>
                      )}
                      {confirmationEmailCount == index + 1 && confirmationEmailCount < 5 && (
                        <span
                          className="add"
                          onClick={() =>
                            setConfirmationEmailCount(
                              (prevConfirmationEmailCount) => ++prevConfirmationEmailCount
                            )
                          }
                        >
                          {trans.t('add')}
                        </span>
                      )}
                    </>
                  </TextField>
                )
              }
            )}
          </div>
        </div>
        <div className="ksd-account-info">
          <h3>{trans.t('ksdAccountInfo')}</h3>
          <div className="fixed-table registered-account">
            <table>
              <thead>
                <tr>
                  <th>{trans.t('select')}</th>
                  <th>No.</th>
                  <th>{trans.t('accountNo')}</th>
                  <th>{trans.t('slbAccountNo')}</th>
                  <th>{trans.t('team')}</th>
                  <th>{trans.t('memo')}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {accountList.map((account, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        setAccountList((accountList) => {
                          return accountList.map((account, _index) => {
                            if (index == _index) {
                              return {
                                ...account,
                                isSelected: true,
                              }
                            } else {
                              return {
                                ...account,
                                isSelected: false,
                              }
                            }
                          })
                        })
                      }}
                    >
                      <td>
                        <Checkbox readOnly checked={account.isSelected} onClick={() => {}} />
                      </td>
                      <td>{index + 1}</td>
                      <td>{account.ksdAccount}</td>
                      <td>{account.slbAccount}</td>
                      <td>{account.organization}</td>
                      <td>{account.memo}</td>
                      <td>
                        <span
                          className="del"
                          onClick={(e) => {
                            e.preventDefault()
                            setAccountList((accountList) =>
                              accountList.filter((_, i) => i !== index)
                            )
                          }}
                        >
                          {trans.t('delete')}
                        </span>
                      </td>
                      <td></td>
                    </tr>
                  )
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <TextField
                      type="text"
                      pattern="[0-9]*"
                      maxLength={12}
                      value={account}
                      onChange={(e) => {
                        if (e.target.validity) {
                          setAccount(e.target.value)
                        }
                      }}
                    />
                  </td>
                  <td>
                    <TextField
                      type="text"
                      pattern="[0-9]*"
                      value={slbAccount}
                      onChange={(e) => setSlbAccount(e.target.value)}
                    />
                  </td>
                  <td>
                    <TextField
                      type="text"
                      value={organization}
                      onChange={(e) => setOrganization(e.target.value)}
                    />
                  </td>
                  <td>
                    <TextField type="text" value={memo} onChange={(e) => setMemo(e.target.value)} />
                  </td>
                  <td>
                    <span className="add" onClick={() => addAccount()}>
                      {trans.t('add')}
                    </span>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            {localStorageAPI.getItem('lang') === 'en'
              ? '* Selected account information will be provided to matched counterparties'
              : '* 선택한 계좌로 대차거래 됩니다(다른 계좌로 주문하려는 경우 변경 후 사용)'}
          </p>
        </div>
        <div className="ksd-account-info">
          <h3>{trans.t('myGroup')}</h3>
          <Box
            role="myGroupBox"
            component="form"
            style={{ padding: '0 30px', display: 'flex', flexWrap: 'wrap' }}
            sx={{
              // '& > :not(style)': { width: '220px', height: '35px', marginRight: '20px' },
              button: {
                width: '35px',
                marginTop: '3px',
                color: 'skyblue',
                marginBottom: '15px',
              },
            }}
            noValidate
            autoComplete="off"
          >
            {myGroupList.map((group, index) => (
              <MyGroupTextFeild
                key={group.id}
                selectedMyGroupId={selectedMyGroupId}
                setSelectedMyGroupId={setSelectedMyGroupId}
                editingMyGroupIndex={editingMyGroupIndex}
                setEditingMyGroupIndex={setEditingMyGroupIndex}
                index={index}
                group={group}
                fetchMyGroupList={fetchMyGroupList}
                getCurrentGroup={getCurrentGroup}
                myGroupList={myGroupList}
                setCurrentGroup={setCurrentGroup}
              ></MyGroupTextFeild>
            ))}
            {myGroupList.length < 10 && (
              <IconButton
                sx={{
                  marginTop: '-',
                  height: '24px !important',
                  width: '24px !important',
                  border: 'solid 1px',
                  verticalAlign: 'middle',
                  backgroundColor: 'transparent !important',
                }}
                onClick={() => handleGroupAddBtn()}
              >
                <AddIcon></AddIcon>
              </IconButton>
            )}
          </Box>
          <div
            className="search-mypage"
            // style={{ width: '100%' }}
          >
            <Autocomplete
              // inputRef={ref}
              // open={true}
              className="mypage-autocomplete"
              blurOnSelect={true}
              clearOnEscape={true}
              id="search-mypage-autocomplete"
              options={companyList}
              // size="small"
              sx={{
                paddingTop: '0px !important',
                paddingBottom: '0px !important',
                button: { background: 'transparent !important' },
                input: {
                  paddingTop: '0px !important',
                  paddingBottom: '0px !important',
                  fontSize: '14px',
                },
                div: {
                  paddingTop: '5px !important',
                  paddingBottom: '5px !important',
                  '&:nth-child(3)': {
                    // backgroundColor: 'yellow !important',
                    top: 'calc(50% - 19px)',
                  },
                },
              }}
              value={autocompeteValue}
              inputValue={autocompeteInputValue}
              freeSolo={true}
              onClose={(e: any, reason: any) => {
                if (autoCompleteCloseFlagCheck()) {
                  return
                }
                if (reason === 'blur') {
                  return
                }
                setCompanyFilter('')
                setAutocompleteInputValue('')
                handleAutocompleteInputClose(e, reason, selectedMyGroupId)
              }}
              onInputChange={(e: any, value, reason) => {
                if (reason === 'reset') {
                  setCompanyFilter('')
                  setAutocompleteInputValue('')
                  return
                }
                setCompanyFilter(value)
                setAutocompleteInputValue(value)
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                handleAutocompleteKeyDown(e, selectedMyGroupId)
              }}
              onHighlightChange={(e, option) => {
                if (option === null) {
                  return
                }
                setCurrentInputAccountId(option.accountId)
              }}
              getOptionLabel={(option) => option.companyName + ' ' + option.label}
              filterOptions={(options) =>
                options.filter((option) => {
                  // console.log('companyFilter', option.label.includes(companyFilter.split(' ')[0]))
                  const optionStr = option.companyName + ' ' + option.label
                  if (optionStr.includes(companyFilter)) {
                    return option
                    // }
                    // if (option.companyName.includes(companyFilter.split)) {
                    //   return option
                    // }
                  }
                })
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.accountId}>
                    {option.companyName} {option.label}
                  </li>
                )
              }}
              renderInput={(params) => {
                return (
                  <MuiTextField
                    // value={}
                    // onChange={(e: any) => setCompanyFilter(e.target.value)}
                    onKeyDown={async (e: any) => {
                      if (e.key === 'Enter') {
                        await httpPostAddCompanyToGroup(selectedMyGroupId, {
                          accountId: currentInputAccountId,
                        })
                        await getCurrentGroup(selectedMyGroupId)
                        return
                      }
                    }}
                    // value={autocompeteInputValue}
                    inputRef={ref}
                    {...params}
                    label=""
                    placeholder={
                      localStorageAPI.getItem('lang') === 'en'
                        ? 'Search'
                        : '기관명 또는 계좌로 검색'
                    }
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          {' '}
                          <SearchIcon sx={{ color: '#3281a8' }} />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                  />
                )
              }}
              popupIcon={''}
            />
            {/* </Autocomplete> */}
            {/* <SearchIcon /> */}
          </div>
          <div className="fixed-table saved-list">
            <table>
              <thead>
                <tr>
                  <th>{trans.t('companyName')}</th>
                  <th>{trans.t('accountNo')}</th>
                  <th>{trans.t('slbAccountNo')}</th>
                  <th>{trans.t('delete')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentGroup &&
                  currentGroup.accountList.map((group, index) => {
                    return (
                      <tr key={index}>
                        <td>{group.companyName}</td>
                        <td
                          onClick={() => {
                            navigator.clipboard.writeText(group.companyCode)
                          }}
                        >
                          {group.companyCode + group.companyPropertyCode}
                          <ContentCopyIcon
                            sx={{
                              float: 'right',
                              fontSize: '14px',
                              // marginRight: '30px',
                              marginTop: '6px',
                              cursor: 'pointer',
                            }}
                          ></ContentCopyIcon>
                        </td>
                        <td>{group.companySLBCode}</td>
                        <td>
                          <CloseIcon
                            sx={{ verticalAlign: 'middle', color: 'crimson', cursor: 'pointer' }}
                            onClick={
                              () => handleDleBtn(selectedMyGroupId, group.accountId)
                              // async () => {
                              //   if (window.confirm(delConfirm)) {
                              //     await httpDelMyGroupCompany(selectedMyGroupId, group.accountId)
                              //     await getCurrentGroup(selectedMyGroupId)
                              //   }
                              // }
                              // () => console.log('test')
                              // httpPostAddCompanyToGroup(selectedMyGroupId, {
                              //   companyCode: group.companyCode,
                              // })
                            }
                          ></CloseIcon>
                        </td>
                        <td></td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </article>
      {passwordPopupOpen && <MyPagePasswordPopup close={() => setPasswordPopupOpen(false)} />}
    </div>
  )
}

export default MyPage
