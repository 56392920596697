import axios from 'axios'
import { localStorageAPI, sessionStorageAPI } from '../storageAPI'

const httpClient = axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? '' : process.env.BASE_URL,
  withCredentials: true,
  paramsSerializer: (params: any) => {
    const urlSearchParams = new URLSearchParams()
    for (const key in params) {
      const value = params[key]
      if (value instanceof Array) {
        value.forEach((value: any) => urlSearchParams.append(key, value))
        continue
      }
      switch (typeof value) {
        case 'object':
          urlSearchParams.append(key, JSON.stringify(params[key]))
          break
        default:
          urlSearchParams.append(key, params[key])
      }
    }
    return urlSearchParams.toString()
  },
})

// http 요청시 자동으로 header Authorization에 token 설정
httpClient.interceptors.request.use((config) => {
  const token = sessionStorageAPI.getItem('token')
  if (!config.headers || !token) return config

  config.headers['Authorization'] = 'Bearer ' + token

  return config
})

let flag = false
// http 요청 응답시 401에러면 재로그인
httpClient.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    if (err.config.url === '/auth/sign-in') {
      return err
    }
    if (err?.response?.status === 403) {
      if (flag) {
        return
      }
      flag = true
      alert(' 인증되지 않은 사용자입니다. \n \n 로그인 페이지로 이동합니다.')
      sessionStorageAPI.removeItem('token')
      window.location.href = '/user/login'
      return Promise.reject()
    }
    if (err?.response?.status === 400) {
      return err.response.data
    }
    if (err?.response?.status === 401 && err.config) {
      if (err.response.data.error === 'user already exist') return err.response
      if (flag) {
        return
      }
      flag = true
      alert(
        ' 타 클라이언트에서 로그인 또는 비밀번호 변경으로 인해 로그인 인증이 만료되었습니다. \n \n 로그인 페이지로 이동합니다.'
      )
      sessionStorageAPI.removeItem('token')
      window.location.href = '/user/login'
    }
    return Promise.reject()
  }
)

export default httpClient
